@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(78, 77, 77);
}
